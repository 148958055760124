import { domDelegate as DomDelegate, doT } from 'core-vendor';
import { animate } from './animate';

const __ = {};
const exports = {
	__: __,
};

__.eventDelegates = {};
__.TRANSITION_TIMEOUT = 1500;

// Returns true if it is a DOM element
__.isValidDOMElement = function (o) {
	return typeof HTMLElement === 'object'
		? o instanceof HTMLElement // DOM2
		: o &&
				typeof o === 'object' &&
				o !== null &&
				o.nodeType === 1 &&
				typeof o.nodeName === 'string';
};

/**
 * return HTMLElement as dummy fallback
 * @returns {HTMLElement} HTMLElement of type/tagName '_'
 */
__.getDummyElement = function () {
	return document.createElement('_');
};

/**
 * Polyfill method for Element.matches
 * (http://davidwalsh.name/element-matches-selector)
 * @param {HTMLElement} el - element toc check
 * @param {string} selector - selector to validate
 * @returns {boolean} element matches?
 */
exports.selectorMatches = function (el, selector) {
	const p = Element.prototype;
	const f =
		p.matches ||
		p.webkitMatchesSelector ||
		p.mozMatchesSelector ||
		p.msMatchesSelector ||
		function (s) {
			return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
		};
	return f.call(el, selector);
};

/**
 * Check if element is hidden
 * @param {HTMLElement} el - element to check for visibility
 * @returns {boolean} element visibility
 */
exports.isHidden = function (el) {
	return el.offsetParent === null;
};

/**
 * isElementVisible
 * @param {HTMLElement} element_ to check if visible
 * @param {HTMLElement} parent_ element's container
 * @param {boolean} fullVisible_ if true only returns true if the all object rect is visible
 * @return {boolean} returns if element is visible
 */
exports.isVisible = function (element_, parent_, fullVisible_) {
	const parentBound = parent_.getBoundingClientRect(),
		rectBound = element_.getBoundingClientRect(),
		rightBoundVisible =
			Math.round(rectBound.right) <= Math.round(parentBound.right) &&
			Math.round(rectBound.right) >= Math.round(parentBound.left),
		leftBoundVisible =
			Math.round(rectBound.left) >= Math.round(parentBound.left) &&
			Math.round(rectBound.left) <= Math.round(parentBound.right);

	if (fullVisible_) {
		return rightBoundVisible && leftBoundVisible;
	}

	return rightBoundVisible || leftBoundVisible;
};

/**
 * handle $(document).ready
 * @param {function} callBack_  - method to be called upon ready
 * @returns {null} void
 */
exports.handleDocumentReady = function (callBack_) {
	const isIE9orBelow =
		/MSIE\s/.test(navigator.userAgent) &&
		parseFloat(navigator.appVersion.split('MSIE')[1]) < 10;
	let checkReadyState = 'loading';

	if (isIE9orBelow) {
		checkReadyState = 'interactive';
	}

	if (document.readyState !== checkReadyState) {
		callBack_();
	} else if (document.addEventListener) {
		document.addEventListener('DOMContentLoaded', callBack_);
	} else {
		document.attachEvent('onreadystatechange', function () {
			if (document.readyState !== 'loading') {
				callBack_();
			}
		});
	}
};

/**
 * get an instance of eventdelegator to register events
 * @param {string} selector_  - to deleagte Events
 * @returns {Object} instance of an event delgator
 * @example
 * DOM_UTILS.getEventDelegate('body').on('eventaction','.selector',handlerFunction);
 */
exports.getEventDelegate = function (selector_) {
	const el = document.querySelector(selector_);

	// check if an event delegate with the same rootElement exists
	if (!__.eventDelegates[selector_] && el) {
		__.eventDelegates[selector_] = new DomDelegate(el);
	}

	return __.eventDelegates[selector_];
};

/**
 * Returns a rendered string.
 * @param {string} template_ - a valid doT template string.
 * @param {Object} data_ - The data to render the template with.
 * @returns {string} rendered template as string (or empty string if parse expection occurred)
 * @example
 * DOM_UTILS.renderTemplate('<div>{{=it.foo}}</div>', {foo: 'bar'});
 * // <div>bar</div>
 */
exports.renderTemplate = function (template_, data_) {
	let renderedHtml = '';
	try {
		renderedHtml = doT.template(template_)(data_);
	} catch (ex) {
		console.warn('renderTemplate Error:', ex);
	}
	return renderedHtml;
};

/**
 * @description test if element is outside of element matched by selector
 * @param {HTMLElement} clickedTarget - event´s clicked target
 * @param {string} selector - selector string
 * @returns {boolean} is inside element
 */
exports.isElementOutsideOfElementWithSelector = function (
	clickedTarget,
	selector,
) {
	let el;

	// first test if the element itself matches the selector
	if (exports.selectorMatches(clickedTarget, selector)) {
		return false;
	}

	// then test if element has a parent with matches the selector (closest)
	el = clickedTarget;

	while (
		!exports.selectorMatches(el, selector) &&
		el.parentNode !== document
	) {
		el = el.parentNode;
	}

	if (exports.selectorMatches(el, selector)) {
		return false;
	} else {
		return true;
	}
};

/**
 * get offset from Element
 * @param {string} selector_ -  css selector
 * @param {HTMLElement} context_ -  context HTMLElement (defaults to 'document')
 * @returns {Object} with offset top and
 */
exports.getOffset = function (selector_, context_) {
	// Preserve chaining for setter
	const elem = exports.getElement(selector_, context_);
	let docElem, win, rect, doc;

	if (!exports.isElement(elem)) {
		return;
	}

	// Support: IE<=11+
	// Running getBoundingClientRect on a
	// disconnected node in IE throws an error
	if (!elem.getClientRects().length) {
		return {
			top: 0,
			left: 0,
		};
	}

	rect = elem.getBoundingClientRect();

	// Make sure element is not hidden (display: none)
	if (rect.width || rect.height) {
		doc = elem.ownerDocument;
		win = window; // getWindow( doc );
		docElem = doc.documentElement;

		return {
			top: rect.top + win.pageYOffset - docElem.clientTop,
			left: rect.left + win.pageXOffset - docElem.clientLeft,
		};
	}

	// Return zeros for disconnected and hidden elements (gh-2310)
	return rect;
};

/**
 * append HTML String to and HTML Element
 * @param {HTMLELement} el_ - elemet to append childnodes to
 * @param {string} htmlString_ - html Content to Apend
 * @return {void}
 */
exports.appendHtmlString = function (el_, htmlString_) {
	let div, elements;

	if (el_ && !!htmlString_) {
		div = document.createElement('div');
		div.innerHTML = htmlString_;
		elements = div.childNodes;

		while (elements.length > 0) {
			el_.appendChild(elements[0]);
		}
	}
};

/**
 * create elements from HTMLString
 * @param {string} htmlString_ - html Content to Apend
 * @return {DOMElement} new HTML Element
 */
exports.createElementsFromHtmlString = function (htmlString_) {
	const div = document.createElement('div');

	if (htmlString_) {
		div.innerHTML = htmlString_;
	}

	return div.childNodes;
};

/**
 * get elements data attribute as Object
 * @param {HTMLElement} elem_ - HTML Element
 * @param {string} attr_ - na of the data attribzte (with or withou 'data-' prefix)
 * @returns {Object|string|null} Object or String
 */
exports.getDataAttribute = function (elem_, attr_) {
	const val =
		elem_.getAttribute('data-' + attr_) || elem_.getAttribute(attr_);
	let data = {};

	try {
		data = JSON.parse(val);
		return data;
	} catch (ex) {
		if (!!val && val.indexOf('{') < 0 && val.indexOf('[') < 0) {
			return val;
		}

		console.error('could not parse data attribute JSON', val);

		return null;
	}
};

/**
 * get an elements closest neighbor by its css-selector
 * @param {HTMLElement} el_ - HTML Element
 * @param {string} selector_ - neighbor to look for
 * @returns {HTMLElement|null} found Element
 */
exports.closest = function (el_, selector_) {
	let matchesFn, el;

	el = el_;

	if (typeof el_.closest === 'function') {
		return el_.closest(selector_);
	}

	// find vendor prefix
	[
		'matches',
		'webkitMatchesSelector',
		'mozMatchesSelector',
		'msMatchesSelector',
		'oMatchesSelector',
	].some(function (fn) {
		if (typeof document.body[fn] === 'function') {
			matchesFn = fn;
			return true;
		}
		return false;
	});

	// traverse parents
	while (el) {
		if (el && el[matchesFn](selector_)) {
			return el;
		}
		el = el.parentElement;
	}

	return null;
};

/**
 * get an elements siblings
 * @param {HTMLElement} el_ - HTML Element
 * @param {string} selector_ - selector of sibling
 * @returns {Array} array with found Elements
 */
exports.siblings = function (el_, selector_) {
	const parentNode = el_.parentNode;
	let siblings = [];

	if (parentNode) {
		siblings = Array.prototype.filter.call(
			parentNode.children,
			function (child) {
				let returnValue = child !== el_;

				if (!!selector_ && returnValue) {
					returnValue = child.matches(selector_);
				}

				return returnValue;
			},
		);
	}
	return siblings;
};

/* From Modernizr */
__.transitionEvent = (function whichTransitionEvent() {
	let t;
	const el = document.createElement('fakeelement');
	const transitions = {
		transition: 'transitionend',
		OTransition: 'oTransitionEnd',
		MozTransition: 'transitionend',
		WebkitTransition: 'webkitTransitionEnd',
	};

	for (t in transitions) {
		if (el.style[t] !== undefined) {
			return transitions[t];
		}
	}
})();

/**
 * observe an element´s transition returning a resolved Promises when the CSS transition is finished
 * @param {HTMLElement} element_ - DOMelement/target to attach the EventListener to
 * @param {string|null} transitionTriggerClass_ - class the triggers the CSS transition (otional)
 * @returns {Promise} Promise returning the transition target
 */
exports.handleTransition = function (element_, transitionTriggerClass_) {
	return new Promise(function (resolve, reject) {
		if (!element_ || !element_.addEventListener) {
			reject('missing params');
		}

		element_.addEventListener(
			__.transitionEvent,
			function transitionCallBack() {
				element_.removeEventListener(
					__.transitionEvent,
					transitionCallBack,
				);

				if (transitionTriggerClass_) {
					element_.classList.remove(transitionTriggerClass_);
				}
				resolve(element_);
			},
		);

		if (transitionTriggerClass_) {
			element_.classList.add(transitionTriggerClass_);
		}

		setTimeout(function () {
			reject('transition timeout');
		}, __.TRANSITION_TIMEOUT);
	});
};

/**
 * observe an element´s css animation returning a resolved Promises when the CSS animation is finished
 * @param {HTMLElement} element_ - DOMelement/target to attach the EventListener to
 * @param {string|null} animationTriggerClass_ - class the triggers the CSS animation (otional)
 * @returns {Promise} Promise returning the animation target
 */
exports.handleCSSAnimation = function (element_, animationTriggerClass_) {
	return new Promise(function (resolve, reject) {
		if (!element_ || !element_.addEventListener) {
			reject('missing params');
		}

		element_.addEventListener('animationend', function animationCallBack() {
			element_.removeEventListener('animationend', animationCallBack);

			if (animationTriggerClass_) {
				element_.classList.remove(animationTriggerClass_);
			}

			resolve(element_);
		});

		if (animationTriggerClass_) {
			element_.classList.add(animationTriggerClass_);
		}

		setTimeout(function () {
			reject('animation timeout');
		}, __.TRANSITION_TIMEOUT);
	});
};

/**
 * @param {string} selector_ - dom css selector
 * @param {HTMLElement|null} context_ - dom context
 * @returns {HTMLElement|null} matching HTML Element
 */
exports.getElement = function (selector_, context_) {
	const context = context_ || document;
	return context.querySelector(selector_) || __.getDummyElement();
};

/**
 * checks if an element a layer content
 * @param {string} selector_ - dom css selector
 * @returns {boolean} is in layer context or not
 */
exports.selectorIsInLayerContext = function (selector_) {
	const selector = '.nm-layer-opened .nm-layer ' + selector_;
	return !!document.querySelector(selector);
};

/**
 * @description check if element is in viewport
 * @param {HTMLElement} element - element to check for
 * @returns {boolean} - returns if element is viewport or not
 */
exports.isInViewport = function (element) {
	const rect = element.getBoundingClientRect(),
		html = document.documentElement;

	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= (window.innerHeight || html.clientHeight) &&
		rect.right <= (window.innerWidth || html.clientWidth)
	);
};

/**
 * @description get the visible vertical percentage of an element in the viewport
 * @param {HTMLElement} element - element to check for
 * @returns {number} - TODO
 */
exports.getVisibleVerticalPercentageInViewport = function (element) {
	const rect = element.getBoundingClientRect();
	let pixelVisible = window.innerHeight - rect.top;

	if (rect.top < 0) {
		pixelVisible = rect.bottom;
	}

	if (pixelVisible <= 0) {
		return 0;
	}

	if (pixelVisible >= element.clientHeight) {
		return 100;
	}

	return (pixelVisible / element.clientHeight) * 100;
};

/**
 * @description Calculates the percentage of the viewport which is covered
 * by the given element. If the element is smaller than the viewport
 * and completely in the viewport, this function returns 100.
 * @param {HTMLElement} element - element to check for
 * @returns {number} - percentage of the viewport covered by the element
 */
/* eslint-disable max-statements */
exports.getViewportPercentageCovered = function (element) {
	let rect, viewPortHeight;

	if (!element) {
		return 0;
	}

	rect = element.getBoundingClientRect();
	viewPortHeight = window.innerHeight;

	// the element is fully above the viewport
	if (rect.top < 0 && rect.bottom <= 0) {
		return 0;
	}

	// the element is fully below the viewport
	if (rect.top > viewPortHeight) {
		return 0;
	}

	// the element is fully in the viewport, we return 100
	// even if the viewport is not fully covered by the elem
	// if we don't do this, small element may never be played
	// because they represent only a small percentage of the
	// viewport
	if (rect.top >= 0 && rect.bottom <= viewPortHeight) {
		return 100;
	}

	// the element is partially above the viewport, we need
	// to calculate the viewport percentage covered by the
	// element
	if (rect.top < 0 && rect.bottom <= viewPortHeight) {
		return (rect.bottom / viewPortHeight) * 100;
	}

	// the element is partially below the viewport, we need
	// to calculate the viewport percentage covered by the
	// element
	if (
		rect.top >= 0 &&
		rect.top < viewPortHeight &&
		rect.bottom > viewPortHeight
	) {
		return ((viewPortHeight - rect.top) / viewPortHeight) * 100;
	}

	// the element is bigger than the viewport
	if (rect.top <= 0 && rect.bottom >= viewPortHeight) {
		return 100;
	}

	// should never happen since all cases are already handled above
	return 0;
};
/* eslint-enable max-statements */

/**
 * check if an HTMLElement is a dummy fallback element of type '_'
 * @param {HTMLElement|string} el_ - element or selector to check
 * @returns {boolean} matching HTML Element
 */
exports.isElement = function (el_) {
	const el = typeof el_ === 'string' ? document.querySelector(el_) : el_;
	return !!el && el.tagName !== '_' && __.isValidDOMElement(el);
};

/**
 * get a list of Elements by their matching CSS Selector
 * @param {string} selector_ - dom css selector
 * @param {HTMLElement|null} context_ - dom context
 * @returns {array} array with matching HTML Elements
 */
exports.getElementsArray = function (selector_, context_) {
	const context = context_ || document;
	return [].slice.call(context.querySelectorAll(selector_));
};

/**
 * remove an element from the DOM
 * @param {HTMLElement} el_ - dom context
 * @returns {void}
 */
exports.removeElement = function (el_) {
	if (el_ && el_.parentNode) {
		el_.parentNode.removeChild(el_);
	}
};

__.now = function () {
	return new Date().getTime();
};

// ===DEBOUNCE and THROTTLE
// https://css-tricks.com/debouncing-throttling-explained-examples/
/**
 * Debounce a function so that it will only fire once
 * @Example
 * window.addEventListener('resize',DOM_UTILS.debounce(myFunction, 500));
 *
 * http://davidwalsh.name/javascript-debounce-function
 *
 * @param {function} func_ - The function to execute
 * @param {int} wait_ - How many milliseconds to debounce
 * @param {boolean} immediate_ - If true, fires at the leading edge
 * @return {function} debounced/wrapped function
 */
exports.debounce = function _debounce(func_, wait_, immediate_) {
	let timeout, later, context, callNow, args;
	return function () {
		context = this; //eslint-disable-line
		args = arguments; //eslint-disable-line
		later = function () {
			timeout = null;
			if (!immediate_) {
				func_.apply(context, args); //eslint-disable-line
			}
		};
		callNow = immediate_ && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait_);
		if (callNow) {
			func_.apply(context, args); //eslint-disable-line
		}
	};
};

/**
 * Throttle a function so that it will not exceed a specified rate
 * @Example
 * window.addEventListener('resize',throttle(myFunction, 500));
 * @param {function} func_ - The function to execute
 * @param {number} wait_ - How many milliseconds to debounce
 * @return {function} throttled/wrapped function
 */
exports.throttle = function _throttle(func_, wait_) {
	let context,
		now,
		remaining,
		later,
		args,
		result,
		timeout = null,
		previous = 0;

	later = function () {
		previous = __.now();
		timeout = null;
		result = func_.apply(context, args);
		context = args = null;
	};

	return function () {
		now = __.now();
		remaining = wait_ - (now - previous);
		context = this; //eslint-disable-line
		args = arguments;

		if (remaining <= 0) {
			clearTimeout(timeout);
			timeout = null;
			previous = now;
			result = func_.apply(context, args);
			context = args = null;
		} else if (!timeout) {
			timeout = setTimeout(later, remaining);
		}

		return result;
	};
};

/**
 * scroll window animation method
 * @param {number} posY_ - scroll to value
 * @param {number|Object} durationOrOptions_ - duration im milliseconds or custom otions
 * @returns {Promise} -
 */
exports.scrollTo = function (posY_, durationOrOptions_) {
	let options = durationOrOptions_;

	if (typeof durationOrOptions_ === 'number') {
		options = {
			duration: durationOrOptions_,
		};
	}

	return animate.animateWindowY(posY_, options);
};

/*
 * scroll element animation method - x-axis
 * @param {number} posX_ - scroll to value
 * @param {number|Object} durationOrOptions_ - duration im milliseconds or custom otions
 * @returns {Promise} -
 */
exports.animateElementX = function (posX_, element_, durationOrOptions_) {
	let options = durationOrOptions_;
	if (typeof durationOrOptions_ === 'number') {
		options = {
			duration: durationOrOptions_,
			property: 'scrollLeft',
		};
	}
	return animate.animateElementProperty(posX_, element_, options);
};

/*
 * scroll element animation method - y-axis
 * @param {number} posX_ - scroll to value
 * @param {number|Object} durationOrOptions_ - duration im milliseconds or custom otions
 * @returns {Promise} -
 */
exports.animateElementY = function (posY_, element_, durationOrOptions_) {
	let options = durationOrOptions_;
	if (typeof durationOrOptions_ === 'number') {
		options = {
			duration: durationOrOptions_,
			property: 'scrollTop',
		};
	}
	return animate.animateElementProperty(posY_, element_, options);
};

/**
 * preloadImagePromise∂
 * @param {string} src_ - image source
 * @return {Promise} image wrapped in a Promise
 */
__.preloadImagePromise = function (src_) {
	return new Promise(function (resolve, reject) {
		const image = new Image();

		image.src = src_;
		image.onload = function () {
			resolve(image);
		};
		image.onerror = function (e) {
			reject(e);
		};
	});
};

/**
 * preloadImages
 * @param {Array} imagesArray_ - array of images
 * @return {Promise} loading state of all Images wrapped in a Promise
 */
exports.preloadImages = function (imagesArray_) {
	const preloadPromises = [];

	imagesArray_.forEach(function (img) {
		preloadPromises.push(__.preloadImagePromise(img.src));
	});

	return Promise.all(preloadPromises);
};

/**
 * get element´s absolute y position (including scroll)
 * @param {HTMLElement} element_ - element to check
 * @returns {Number} position Y
 */
exports.getElementsAbsoluteTopPosition = function (element_) {
	const elementTop = element_.getBoundingClientRect().top;
	return window.pageYOffset + elementTop;
};

export { exports as dom };
