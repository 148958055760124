/**
 * Utility module to identify which kind of device
 * is displaying Video and dynamically manipulate
 * video controls (NEMOBA-6340)
 *
 * author:  Luca Marzano <lm@dasburo.com>
 * version: 0.5
 * ticket:  https://imt.dasburo.com/browse/NEMOBA-6340
 *
 */

const __ = {};

const exports = {
	__: __,
};

__.oDefaults = {
	classIsIOS: 'nm-is-ios',
	classIsIPad: 'nm-is-ipad',
	classIsIPhone: 'nm-is-iphone',
};

/*
 * is-iPhone
 */
exports.isiPhone = function () {
	return __.getDevice() === 'iphone';
};

/*
 * is-iPod
 */
exports.isiPod = function () {
	return __.getDevice() === 'ipod';
};

/*
 * is-iPad
 */
exports.isiPad = function () {
	return __.getDevice() === 'ipad';
};

/*
 * isAndroid
 */
exports.isAndroid = function () {
	return __.getDevice() === 'android';
};

/*
 * isiOS
 */
exports.isiOS = function () {
	return exports.isiPhone() || exports.isiPad() || exports.isiPod();
};

/*
 * isMobile
 */
exports.isMobile = function () {
	return exports.isiOS() || exports.isAndroid();
};

/*
 * if (device.match(/mobile/i) !== null) {
 */
__.addDeviceClassToBody = function () {
	if (exports.isiOS()) {
		document.querySelector('body').classList.add(__.oDefaults.classIsIOS);

		if (exports.isiPhone()) {
			document
				.querySelector('body')
				.classList.add(__.oDefaults.classIsIPhone);
		} else if (exports.isiPad()) {
			document
				.querySelector('body')
				.classList.add(__.oDefaults.classIsIPad);
		}
	}
};

/*
 * getDevice
 */
__.getDevice = function () {
	let ua = window.navigator.userAgent.toLowerCase();
	if (ua.match(/ipod/i) !== null) {
		ua = 'ipod';
	} else if (ua.match(/ipad/i) !== null) {
		ua = 'ipad';
	} else if (ua.match(/iphone/i) !== null) {
		ua = 'iphone';
	} else if (ua.match(/android/i) !== null) {
		if (ua.match(/mobile/i) !== null) {
			ua = 'android';
		}
	} else {
		ua = 'default';
	}
	return ua;
};

/*
 * initialize
 */
__.initialize = function () {
	__.addDeviceClassToBody();
};

/*
 * run all
 */
__.initialize();

export { exports as device };
