/**
 * @description module to handle fetchcalls
 * @exports utils/fetch
 */

const exports = {};

/**
 * process status of fetch-call
 * @param {Object} response response-object from fetch-api
 * @returns {*} resolve or reject of promise
 */
function processStatus(response) {
	if (response.status === 200 || response.status === 0) {
		return Promise.resolve(response);
	} else {
		return Promise.reject(new Error('Error fetching: ' + response.url));
	}
}

/**
 * get BLOB from request response
 * @param {Object} response - response object
 * @returns {Object} BLOB
 */
function parseBlob(response) {
	return response.blob();
}

/**
 * get JSON from request response
 * @param {Object} response - response object
 * @returns {Object} JSON object
 */
function parseJson(response) {
	return response.json();
}

/**
 * get a BLOB (Binary Large OBject) from resource url
 * @param {string} url - url to fetch BLOB from
 * @param {Object} options - request options
 * @returns {Promise} BLOG wrapped in a promise
 */
exports.getBlob = function (url, options) {
	const OPTIONS = options || {};
	return fetch(url, OPTIONS).then(processStatus).then(parseBlob);
};
/**
 * get a JSON from resource url
 * @param {string} url - url to fetch BLOB from
 * @param {Object} options - request options
 * @returns {Promise} JSON wrapped in a promise
 */
exports.getJson = function (url, options = {}) {
	return fetch(url, options).then(processStatus).then(parseJson);
};

export { exports as fetch };
