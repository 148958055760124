const __ = {};
const exports = {
	__: __,
};

/**
 * load js asynchronously via require
 * @param {string} url_ - the URL of the js source
 * @param {Object} item_ - the script item
 * @return {Promise} - resolves when successfully loaded, rejects else
 */
exports.loadURL = function (url_, item_ = {}) {
	return new Promise(function (resolve, reject) {
		const script = document.createElement('script');

		script.onerror = function () {
			reject(new Error('could not load Script from ' + url_));
		};
		if (script.readyState) {
			// IE
			script.onreadystatechange = function () {
				if (
					script.readyState === 'loaded' ||
					script.readyState === 'complete'
				) {
					script.onreadystatechange = null;
					resolve();
				}
			};
		} else {
			// Others
			script.onload = function () {
				resolve();
			};
		}

		// copy data attributes
		[...(item_.attributes || [])].forEach((attr) => {
			if (/^data-/.test(attr.name)) {
				script.setAttribute(attr.name, attr.value);
			}
		});

		script.src = url_;

		document.getElementsByTagName('head')[0].appendChild(script);
	});
};

export { exports as jsLoader };
