import Easing from './easing';

const ANIMATION_STATES = {
	RUNNING: 0,
	COMPLETE: 1,
	TERMINATED: 2,
	INTERRUPTED: 4,
};

const defaultOptions = {
	duration: 500,
	maxDuration: 5000,
	element: window,
	easing: 'none',
};

export default class Animation {
	constructor(targetValue, options) {
		this.options = Object.assign({}, defaultOptions, options);
		this.elapsedTime = 0;
		this.currentValue = this.options.startValue;
		this.targetValue = targetValue;
		this.valueChange = this.targetValue - this.options.startValue;
		this.easing = new Easing(this.options.easing);
		this.render = this.options.renderFunction;
		this.animationFrameID = null;
		this.interrupted = false;
	}

	getDelta() {
		return Math.abs(this.valueChange);
	}

	startAnimation() {
		return new Promise((resolve, reject) => {
			this.promiseResolveFn = resolve;
			this.promiseRejectFn = reject;
			this.animationStep();
		});
	}

	interruptAnimation() {
		this.interrupted = true;
	}

	animationStep(timeStamp) {
		const now = timeStamp || 0;

		this.startTime = this.startTime || now;
		this.elapsedTime = now - this.startTime;
		this.currentValue = this.easing.getValue(
			this.elapsedTime.toFixed(0),
			this.options.startValue,
			this.valueChange,
			this.options.duration,
		);

		if (this.isAnimationRunning()) {
			this.render(this);
			this.animationFrameID = window.requestAnimationFrame(
				this.animationStep.bind(this),
			);
		}
	}

	setFinalState() {
		this.currentValue = this.targetValue;
		this.render(this);
	}

	getAnimationState() {
		if (this.interrupted) {
			return ANIMATION_STATES.INTERRUPTED;
		}
		if (this.elapsedTime > this.options.duration) {
			return ANIMATION_STATES.COMPLETE;
		}
		if (this.elapsedTime > this.options.maxDuration) {
			return ANIMATION_STATES.TERMINATED;
		}
		if (this.isTargetValueReached()) {
			return ANIMATION_STATES.COMPLETE;
		}

		return ANIMATION_STATES.RUNNING;
	}

	isTargetValueReached() {
		if (this.valueChange < 0) {
			return this.currentValue <= this.targetValue;
		} else {
			return this.currentValue >= this.targetValue;
		}
	}

	isAnimationRunning() {
		switch (this.getAnimationState()) {
			case ANIMATION_STATES.COMPLETE:
				this.completeAnimation(true);
				this.promiseResolveFn(this);
				return false;
			case ANIMATION_STATES.TERMINATED:
				this.completeAnimation(true);
				this.promiseRejectFn(new Error('Animation was terminated'));
				return false;
			case ANIMATION_STATES.INTERRUPTED:
				this.completeAnimation(false);
				this.promiseResolveFn(this);
				return false;
			case ANIMATION_STATES.RUNNING:
				return true;
			default:
				return true;
		}
	}

	completeAnimation(setFinalState) {
		window.cancelAnimationFrame(this.animationFrameID);

		if (setFinalState) {
			this.setFinalState();
		}
	}
}
