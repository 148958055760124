import { dom as DOM_UTILS } from './dom';
const __ = {},
	api = {
		__: __,
	},
	registered = [],
	options = {
		touchonly: false,
		swipedistance: 15,
	},
	UP = 'up',
	DOWN = 'down',
	LEFT = 'left',
	RIGHT = 'right';
__.mouseMoveTimeout = null;

__.swipePosition = {
	direction: {
		vertical: '',
		horizontal: '',
	},
	vertical: 0,
	horizontal: 0,
};

__.deactivateSwipe = function (event_, target_, object_) {
	DOM_UTILS.getEventDelegate('body').off('mousemove', object_.selector);
	DOM_UTILS.getEventDelegate('body').off('mouseup', object_.selector);
	DOM_UTILS.getEventDelegate('body').off('touchmove', object_.selector);
	DOM_UTILS.getEventDelegate('body').off('touchend', object_.selector);

	if (object_.position.move) {
		object_.callback(object_.position, target_);
	}
	object_.position.move = null;

	if (__.mouseMoveTimeout) {
		clearTimeout(__.mouseMoveTimeout);
	}
};

__.getEventPos = function (event_) {
	const pos = {};
	if (event_.type === 'touchmove' || event_.type === 'touchend') {
		if (event_.originalEvent) {
			pos.x = event_.originalEvent.pageX;
			pos.y = event_.originalEvent.pageY;
		} else if (event_.changedTouches) {
			pos.x = event_.changedTouches[0].pageX;
			pos.y = event_.changedTouches[0].pageY;
		}
	} else {
		pos.x = event_.pageX;
		pos.y = event_.pageY;
	}

	return pos;
};

__.swipe = function (event_, target_, object_) {
	const pos = __.getEventPos(event_),
		objectWidth = DOM_UTILS.getElement(object_.selector).offsetWidth;
	if (pos.x >= object_.position.x + object_.options.swipedistance) {
		object_.position.direction.horizontal = RIGHT;
	} else if (pos.x <= object_.position.x - object_.options.swipedistance) {
		object_.position.direction.horizontal = LEFT;
	}

	if (pos.y >= object_.position.y + object_.options.swipedistance) {
		object_.position.direction.vertical = DOWN;
	} else if (pos.y <= object_.position.y - object_.options.swipedistance) {
		object_.position.direction.vertical = UP;
	}
	object_.position.endX = pos.x;
	object_.position.endY = pos.y;

	object_.position.move = ((pos.x - object_.position.x) / objectWidth) * 100;

	if (object_.swipeCallback) {
		object_.swipeCallback(object_.position, target_);
	}
};

__.activateSwipe = function (event_, swipeTarget_, object_) {
	// eslint-disable-line max-statements
	let mouseEvent;
	// reset values
	object_.position.direction.horizontal = '';
	object_.position.direction.vertical = '';

	object_.position.x = 0;
	object_.position.endX = 0;
	object_.position.y = 0;
	object_.position.endY = 0;

	if (event_.type === 'touchstart') {
		if (event_.originalEvent) {
			object_.position.x = event_.originalEvent.pageX;
			object_.position.y = event_.originalEvent.pageY;
		} else if (event_.changedTouches) {
			object_.position.x = event_.changedTouches[0].pageX;
			object_.position.y = event_.changedTouches[0].pageY;
		}
	} else {
		object_.position.x = event_.pageX;
		object_.position.y = event_.pageY;
	}

	if (!object_.options.touchonly) {
		DOM_UTILS.getEventDelegate('body').on(
			'mousemove',
			object_.selector,
			function (e, target_) {
				__.swipe(e, target_, object_);
			},
		);
		DOM_UTILS.getEventDelegate('body').on(
			'mouseup',
			object_.selector,
			function (e, target_) {
				__.deactivateSwipe(e, target_, object_);
			},
		);
	}
	DOM_UTILS.getEventDelegate('body').on(
		'touchmove',
		object_.selector,
		function (e, target_) {
			__.swipe(e, target_, object_);
		},
	);
	DOM_UTILS.getEventDelegate('body').on(
		'touchend',
		object_.selector,
		function (e, target_) {
			__.swipe(e, target_, object_);
		},
	);

	if (__.mouseMoveTimeout) {
		clearTimeout(__.mouseMoveTimeout);
	}
	__.mouseMoveTimeout = setTimeout(function () {
		if (document.createEvent) {
			mouseEvent = document.createEvent('MouseEvent');
		} else {
			mouseEvent = new MouseEvent('click');
		}
		__.deactivateSwipe(mouseEvent, swipeTarget_, object_);
	}, 300);
};

__.onStart = function (swipeObject_) {
	return function (e, target_) {
		__.activateSwipe(e, target_, swipeObject_);
	};
};

__.addEvents = function () {
	let i, swipeObject;

	for (i = 0; i < registered.length; i++) {
		if (!registered[i].registered) {
			swipeObject = registered[i];

			if (!registered[i].options.touchonly) {
				DOM_UTILS.getEventDelegate('body').on(
					'mousedown',
					swipeObject.selector,
					__.onStart(swipeObject),
				);
			}
			DOM_UTILS.getEventDelegate('body').on(
				'touchstart',
				swipeObject.selector,
				__.onStart(swipeObject),
			);

			registered[i].registered = true;
		}
	}
};

__.checkOptions = function (options_, swipeObject_) {
	let swipeObjectOptions = options,
		swipeObject = swipeObject_;

	if (options_) {
		if (options_.touchonly) {
			swipeObjectOptions.touchonly = options_.touchonly;
		}
		if (options_.swipedistance) {
			swipeObjectOptions.swipedistance = options_.swipedistance;
		}
	}
	swipeObject.options = swipeObjectOptions;

	return swipeObject;
};

__.register = function (callback_, selector_, options_, swipeCallback_) {
	let swipeObject = {};
	if (callback_) {
		if (selector_ && selector_.length > 0) {
			swipeObject.selector = selector_;
			swipeObject.item = document.querySelector(selector_);
			swipeObject.callback = callback_;
			swipeObject.swipeCallback = swipeCallback_;
			swipeObject.initialized = false;
			swipeObject.position = __.swipePosition;
			swipeObject = __.checkOptions(options_, swipeObject);

			registered.push(swipeObject);
			__.addEvents();
		} else {
			throw new Error('swipe.js --> no selector defined');
		}
	} else {
		throw new Error('swipe.js --> no callback function defined');
	}
};

__.unregister = function (selector) {
	let i, swipeObject;

	if (selector && selector.length > 0) {
		for (i = 0; i < registered.length; i++) {
			swipeObject = registered[i];

			if (swipeObject.selector === selector && swipeObject.registered) {
				swipeObject.registered = false;
				DOM_UTILS.getEventDelegate('body').off('mousedown', selector);
				DOM_UTILS.getEventDelegate('body').off('mousemove', selector);
				DOM_UTILS.getEventDelegate('body').off('mouseup', selector);
				DOM_UTILS.getEventDelegate('body').off('touchstart', selector);
				DOM_UTILS.getEventDelegate('body').off('touchmove', selector);
				DOM_UTILS.getEventDelegate('body').off('touchend', selector);
			}
		}
	}
};

api.register = __.register;
api.unregister = __.unregister;
api.UP = UP;
api.DOWN = DOWN;
api.RIGHT = RIGHT;
api.LEFT = LEFT;

export { api as swipe };
