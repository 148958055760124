import { doT } from 'core-vendor';

/**
 * The main template rendering module.
 * @version 1.0a1
 * @param {DotJS} doT -  DotJS Lib
 * @returns {module} module
 * @exports core/template
 */
const __ = {},
	exports = {
		__: __,
	};

/**
 * Returns a rendered string.
 * @param {string} tpl - a valid doT template string.
 * @param {Object} data - The data to render the template with.
 * @returns {string} HTML String
 * @example
 * template.render('<div>{{=it.foo}}</div>', {foo: 'bar'});
 * // <div>bar</div>
 */
exports.render = function (tpl, data) {
	let rendered = '';
	try {
		rendered = doT.template(tpl)(data);
	} catch (e) {
		console.error('Error in dOT-template', e);
	}
	return rendered;
};

export { exports as template };
