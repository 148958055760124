const __ = {};
const exports = {
	__: __,
};

// Run all 50 ms.
__.defaultIntervalTime = 50;

/*
 * The actual polling method.
 */
__.run = function (fn, timeout, intervalTime, resolve, reject) {
	// eslint-disable-line max-params
	let interval, runsFor, returnValue;
	runsFor = 0;
	returnValue = fn();
	if (returnValue) {
		resolve(returnValue);
	} else {
		interval = setInterval(function () {
			returnValue = fn();
			if (returnValue) {
				clearInterval(interval);
				resolve(returnValue);
			} else if (runsFor > timeout) {
				clearInterval(interval);
				reject(new Error());
			}
			runsFor += intervalTime;
		}, intervalTime);
	}
};

/**
 * Starts polling.
 * @param {Function} fn - A function that gets called until it returns a truthy value.
 * @param {Number} timeout - The timeout. Rejects the promise if elapsed.
 * @param {Number} intervalTime_ - The duration (ms) between each call
 * (default 50).
 * @returns {Promise} A Promise that either:
 *    - resolves with the return value of fn
 *    - or rejects with an Error object.
 */
exports.wait = function (fn, timeout, intervalTime_) {
	// If no intervalTime is set, use the default.
	let intervalTime = intervalTime_ || __.defaultIntervalTime;

	// Return a new Promise and start polling.
	return new Promise(function (resolve, reject) {
		__.run(fn, timeout, intervalTime, resolve, reject);
	});
};

export { exports as polling };
