(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("core-vendor"));
	else if(typeof define === 'function' && define.amd)
		define("core-utils", ["core-vendor"], factory);
	else if(typeof exports === 'object')
		exports["core-utils"] = factory(require("core-vendor"));
	else
		root["core-utils"] = factory(root["core-vendor"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__954__) => {
return 