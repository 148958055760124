import Animation from './animation';

let __ = {},
	exports = {
		__: __,
	};

__.runningAnimations = new Map();

__.createNewAnimation = function (targetValue, options) {
	let elementAnimations = __.runningAnimations.get(options.element);

	if (!elementAnimations) {
		elementAnimations = {};
	}

	if (
		elementAnimations[options.property] &&
		elementAnimations[options.property].interruptAnimation
	) {
		elementAnimations[options.property].interruptAnimation();
		delete elementAnimations[options.property];
	}

	elementAnimations[options.property] = new Animation(targetValue, options);
	__.runningAnimations.set(options.element, elementAnimations);

	return elementAnimations[options.property];
};

__.renderWindowY = function (animation) {
	window.scrollTo(0, animation.currentValue);
};

__.renderElementProperty = function (animation) {
	animation.options.element[animation.options.property] =
		animation.currentValue;
};

exports.animateWindowY = function (targetValue_, options_) {
	const defaultOptions = {
		property: 'pageYOffset',
		easing: 'linear',
		startValue: Math.round(window.pageYOffset),
		renderFunction: __.renderWindowY,
	};
	const options = Object.assign({}, defaultOptions, options_);
	const targetValue =
		isNaN(targetValue_) || Math.round(targetValue_) < 0 ? 0 : targetValue_;

	return exports.animateElementProperty(targetValue, window, options);
};

exports.animateElementProperty = function (targetValue_, element_, options_) {
	const defaultOptions = {
		element: element_,
		easing: 'easeOutSine',
		startValue: 0,
		renderFunction: __.renderElementProperty,
	};
	const options = Object.assign({}, defaultOptions, options_);

	if (!options.property || isNaN(targetValue_) || !element_) {
		return Promise.reject(
			new Error('animateElementProperty --> wrong  params'),
		);
	}

	options.startValue = Math.round(
		parseFloat(element_[options.property] || 0),
	);
	const animation = __.createNewAnimation(targetValue_, options);

	return animation.startAnimation();
};

exports.animateElementStyleProperty = function (
	targetValue_,
	element_,
	options_,
) {
	return exports.animateElementProperty(
		targetValue_,
		element_.style,
		options_,
	);
};

export { exports as animate };
