export default class Easing {
	constructor(type) {
		this.initEasing(type);
	}

	getValue(t, b, c, d) {
		return this.easing(t, b, c, d);
	}

	initEasing(type) {
		switch (type) {
			case 'none':
			case 'noEasing':
			case 'linear':
				this.easing = this.noEasing;
				break;
			case 'noEasingInt':
				this.easing = this.noEasingInt;
				break;
			case 'easeInQuad':
				this.easing = this.easeInQuad;
				break;
			case 'easeOutExpo':
				this.easing = this.easeOutExpo;
				break;
			case 'easeInOutSine':
				this.easing = this.easeInOutSine;
				break;
			case 'easeInSine':
				this.easing = this.easeInSine;
				break;
			case 'easeOutSine':
			default:
				this.easing = this.easeOutSineFn;
		}
	}

	// ===============easing functions===================
	// http://robertpenner.com/easing/
	// http://upshots.org/actionscript/jsas-understanding-easing
	/**
	 * @example:
	 *    var nextStep,bound = this,
	 *        t = bound.elapsedTime.toFixed(0), //currentTime
	 *        d = bound.duration, //duration
	 *        c = bound.valueChange,//value change/diff
	 *        b = bound.startValue; //initial/start value
	 *        nextStep=__.easeInOutSine(t,b,c,d);
	 * @param {number} t - current time/elapsed time in ms or frames
	 * @param {number} b - start value befor the tween began
	 * @param {number} c - value difference from start to end
	 * @param {number} d - overall duration for this animation in ms or frames
	 * @returns {number} next calculated value
	 */
	easeInOutSine(t, b, c, d) {
		return (c / 2) * (1 - Math.cos((Math.PI * t) / d)) + b;
	}

	easeInSine(t, b, c, d) {
		return c * (1 - Math.cos((t / d) * (Math.PI / 2))) + b;
	}

	easeOutSineFn(t, b, c, d) {
		return c * (1 - Math.cos((t / d) * (Math.PI / 2))) + b;
	}

	noEasing(t, b, c, d) {
		return (c * t) / d + b;
	}

	noEasingInt(t, b, c, d) {
		return Math.round(this.noEasing(t, b, c, d), 0);
	}

	easeInQuad(t, b, c, d) {
		return c * (t /= d) * t + b; // eslint-disable-line
	}

	easeOutExpo(t, b, c, d) {
		return c * (-Math.pow(2, (-10 * t) / d) + 1) + b;
	}
}
